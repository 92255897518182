import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询工单管理列表
export function listWorkOrder(query: any) {
  return request({
    url: "/factory/workOrder/list",
    method: "get",
    params: query,
  });
}

// 查询工单管理详细
export function getWorkOrder(query: any) {
  return request({
    url: "/factory/workOrder/getInfo",
    method: "get",
    params: query,
  });
}

// 新增工单管理
export function addWorkOrder(data: any) {
  return request({
    url: "/factory/workOrder",
    method: "post",
    data: data,
  });
}

// 修改工单管理
export function updateWorkOrder(data: any) {
  return request({
    url: "/factory/workOrder",
    method: "put",
    data: data,
  });
}

// 删除工单管理
export function delWorkOrder(data: any) {
  return request({
    url: "/factory/workOrder/removes",
    method: "delete",
    data: data,
  });
}

// 切换状态
export function checkStatus(data: any) {
  return request({
    url: "/factory/workOrder/checkStatus",
    method: "put",
    data: data,
  });
}

// 分组列表
export function getListGroup(query: any) {
  return request({
    url: "/factory/workOrder/listGroup",
    method: "get",
    params: query,
  });
}
